
import axios from "axios";
import { AWS_API } from "../../globalVariables";
class ApiGatewayService {
    
    async getFormConfiguration(formIdFromUrl) {
        console.log("formIdFromUrl: ",formIdFromUrl);
        // const config = {
        //     headers: {authorization: token, appclientid: userPoolClientId, userpoolid: userPoolId},
        //     params: { email: email }
        // }
        const response = await axios.get(`${AWS_API}/${formIdFromUrl}`);
        return response;
    }

    async getDropDownData(dropDownUrl) {
        console.log("dropDownUrl: ", dropDownUrl);
        const response = await axios.get(dropDownUrl);
        return response;
    }

    async postFormData(formSubmitUrl, formObject) {
        console.log("formSubmitUrl: ", formSubmitUrl);
        const response = await axios.post(formSubmitUrl, formObject);
        return response;
    }

}

const apiGatewayService = new ApiGatewayService();
export default apiGatewayService;
