import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Form from './Form';
import Modal from 'react-modal';

// Set the app element for React-Modal
Modal.setAppElement('#root');

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/:formId" element={<Form />} />
        <Route path="/" element={<Form />} />
      </Routes>
    </Router>
  );
}

export default App;
