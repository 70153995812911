import React from 'react';

const Navigation = ({ sections, activeSectionIndex, onSectionClick }) => {
    // Safeguard against undefined sections
    if (!sections) return null;

    return (
      <div className="tabs">
        {sections.map((section, index) => (
          <button 
            key={index} 
            className={`tab ${index === activeSectionIndex ? 'active' : ''}`} 
            onClick={() => onSectionClick(index)}>
            {section.title}
          </button>
        ))}
      </div>
    );
  };
  
  
  export default Navigation; // Default export